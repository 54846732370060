<template>
  <div style="height: 100vh">
    <div
      style="position: fixed; top: 20px; z-index: 2; backdrop-filter: blur(2px)"
      v-if="Loaderstatus"
    >
      <Spinner />
    </div>
    <div class="login-main-container">
      <div class="h-100 d-flex align-items-center justify-content-center">
        <div class="login-main-sub-container position-relative p-0">
          <b-row class="m-0 w-100">
            <b-col class="p-0">
              <div
                class="pt-3 w-100 d-flex justify-content-center align-items-baseline"
              >
                <!-- <img src="../assets/newimgs/GREYLOGO.svg" alt="" /> -->
                <img
                  src="https://d33yrtc8qntbdn.cloudfront.net/Hire3xAssests/Logo/SVG/Hire3x_Website_Header.svg"
                  style="
                    display: block;
                    visibility: visible;
                    opacity: 1;
                    height: 40px;
                  "
                  alt=""
                />
              </div>
            </b-col>
          </b-row>
          <b-row class="m-0 h-100 w-100">
            <b-col class="p-0">
              <div class="testdetails-container mt-3">
                <h1 class="login-rightcont-headtxt text-center">
                  Do's and Don'ts
                </h1>
                <video
                  class="w-100 h-100"
                  id="proctor"
                  autoplay
                  muted
                  hidden
                ></video>
                <canvas
                  id="proctoring"
                  width="200"
                  height="150"
                  hidden
                ></canvas>
                <div class="d-flex align-items-center flex-column">
                  <div style="height: calc(100vh - 280px); width: 95%">
                    <div
                      style="height: 90%"
                      class="overflow-auto pr-2 dodontlis"
                    >
                      <ol class="dodont-instructions">
                        <li
                          class="mt-3"
                          v-for="(item, index) in testDetails.config
                            .dosAndDonts"
                          :key="index"
                        >
                          {{ item.value }}
                        </li>
                      </ol>
                    </div>
                    <div
                      class="pt-3 ml-3 d-flex flex-column dodontcheckbox"
                      style="height: 10%"
                    >
                      <hr class="dnd-hr" />
                      <b-form-checkbox
                        id="checkbox-1"
                        name="checkbox-1"
                        value="accepted"
                        v-model="checked"
                      >
                        I accept all the above-mentioned terms & conditions
                      </b-form-checkbox>
                    </div>
                    <div
                      class="terms-ncondition-txt mt-2"
                      v-if="notChecked && !checked"
                    >
                      You must accept the terms and conditions to continue
                    </div>
                  </div>
                </div>
              </div>
              <hr class="mb-0" />
              <div
                class="p-3 d-flex align-items-center justify-content-between bluebtns"
              >
                <b-button
                  variant="outline-primary"
                  @click="$bvModal.show('leavetestmodal')"
                  >Leave Test</b-button
                >
                <div class="position-relative">
                  <div class="bluebtns dd-tooltip-trigger">
                    <b-button
                      variant="primary"
                      :disabled="!checked"
                      @click="startTestBtn()"
                      >Continue</b-button
                    >
                    <div v-if="!checked" class="dd-tooltip">
                      You must accept the terms and conditions to continue
                    </div>
                  </div>
                </div>
                <!-- <div class="position-relative">
                  <div class="dosdontbtnhover">
                    <b-button
                      variant="primary"
                      :disabled="!checked"
                      @click="!checked ? '' : startTestBtn()"
                      >Continue</b-button
                    >
                  </div>
                  <div class="dosndosnts-proc-tooltip" v-if="!checked">
                    You must accept the terms and conditions to continue
                  </div>
                </div> -->
              </div>
            </b-col>
          </b-row>
        </div>
        <b-modal
          id="leavetestmodal"
          size="md"
          centered
          hide-footer
          hide-header
          no-close-on-backdrop
          :no-close-on-esc="true"
        >
          <div
            class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
          >
            Alert !
          </div>
          <div
            @click="$bvModal.hide('leavetestmodal')"
            class="modalcloseicon pointer"
          >
            <img
              class="rotate-on-hover"
              src="../assets/newimgs/closeicon.svg"
              alt=""
            />
          </div>
          <div
            class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
          >
            <div>
              <img src="../assets/newui-img/leavetest.svg" alt="" />
            </div>
            <div class="newui-modal-header mt-4">
              Are you sure you want to leave the test ?
            </div>
            <div class="newui-modal-bodytxt mt-3">
              Please note this action cannot be undone
            </div>
            <div class="bluebtns mt-5">
              <b-button
                class="mr-3 pl-4 pr-4"
                variant="outline-primary"
                @click="$bvModal.hide('leavetestmodal')"
                >Cancel</b-button
              >
              <b-button variant="primary" class="pl-4 pr-4" @click="leave()"
                >Leave Test</b-button
              >
            </div>
          </div>
        </b-modal>
      </div>
    </div>
    <b-modal id="locationmodal" centered hide-footer hide-header size="xl">
      <div
        class="newmodalheadings justify-content-center text-center d-flex align-items-center"
      >
        Enable System Permissions
      </div>
      <div
        @click="$bvModal.hide('locationmodal')"
        class="modalcloseicon pointer"
      >
        <img
          class="rotate-on-hover"
          src="../assets/newimgs/closeicon.svg"
          alt=""
        />
      </div>
      <div class="mt-5 ml-3">
        <b-row>
          <b-col>
            <div class="mt-2 step-subtext">
              1. Please Click on view site information to allow all permissions
            </div>
            <div class="mt-2">
              <img src="../assets/images/step1.svg" alt="" />
            </div>
          </b-col>
          <b-col>
            <div class="mt-2 step-subtext">
              2. Please Enable all permissions
            </div>
            <div class="mt-2">
              <img src="../assets/images/step2.svg" alt="" />
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="mt-4 ml-3">
        <b-row>
          <b-col>
            <div class="mt-2 step-subtext">
              3. After enabling Permissions, Reload the page
            </div>
            <div class="mt-2">
              <img src="../assets/images/step3.svg" alt="" />
            </div>
          </b-col>
        </b-row>
      </div>
      <div
        class="bluebtns d-flex align-items-center justify-content-center mt-4"
      >
        <b-button
          variant="primary"
          class="px-5"
          @click="$bvModal.hide('locationmodal'), reload()"
          >Okay</b-button
        >
      </div>
    </b-modal>
    <b-modal
      id="agentmodal"
      size="lg"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
      :no-close-on-esc="true"
    >
      <div
        class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
      >
        Alert !
      </div>
      <div @click="$bvModal.hide('agentmodal')" class="modalcloseicon pointer">
        <img
          class="rotate-on-hover"
          src="../assets/newimgs/closeicon.svg"
          alt=""
        />
      </div>
      <div
        class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
      >
        <div>
          <img src="../assets/newui-img/alertimage.svg" />
        </div>
        <div class="newui-modal-bodytxt mt-4 mb-3">
          "Agent not working!!!" <br />
          Please follow the below steps
        </div>
        <div class="mt-2 step-subtext text-center">
          1. Go to windows and search “Hire3x Proctoring Agent”, and allow all
          the permissions to install again. (If already installed follow the
          below step)
        </div>
        <div class="mt-3 step-subtext text-center">
          2. If the application is installed correctly then it will appear in
          the “System tray”, right-click on the application in the System tray
          and select "Start service".
        </div>
        <div class="bluebtns mt-5">
          <b-button
            class="mr-1 px-5"
            variant="primary"
            @click="$bvModal.hide('agentmodal')"
            >Okay</b-button
          >
        </div>
      </div>
    </b-modal>
    <b-modal
      id="devdisconected"
      size="lg"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
      :no-close-on-esc="true"
    >
      <div
        class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
      >
        Alert !
      </div>
      <div
        @click="$bvModal.hide('devdisconected')"
        class="modalcloseicon pointer"
      >
        <img
          class="rotate-on-hover"
          src="../assets/newimgs/closeicon.svg"
          alt=""
        />
      </div>
      <div
        class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
      >
        <div>
          <img src="../assets/newui-img/alertimage.svg" alt="" />
        </div>
        <div class="newui-modal-bodytxt mt-4">
          "Please ensure all external devices are disconnected before starting
          the test."
        </div>
        <div class="bluebtns mt-5">
          <b-button
            class="mr-1 pl-4 pr-4"
            variant="primary"
            @click="$bvModal.hide('devdisconected')"
            >Okay</b-button
          >
        </div>
      </div>
    </b-modal>
    <b-modal
      id="vmachine"
      size="lg"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
      :no-close-on-esc="true"
    >
      <div
        class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
      >
        Alert !
      </div>
      <div @click="$bvModal.hide('vmachine')" class="modalcloseicon pointer">
        <img
          class="rotate-on-hover"
          src="../assets/newimgs/closeicon.svg"
          alt=""
        />
      </div>
      <div
        class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
      >
        <div><img src="../assets/newui-img/alertimage.svg" alt="" /></div>
        <div class="newui-modal-bodytxt mt-4">
          "Please stop the virtual machine before starting the test."
        </div>
        <div class="bluebtns mt-5">
          <b-button
            class="mr-1 px-5"
            variant="primary"
            @click="$bvModal.hide('vmachine')"
            >Okay</b-button
          >
        </div>
      </div>
    </b-modal>
    <b-modal
      id="someprob"
      size="lg"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
      :no-close-on-esc="true"
    >
      <div
        class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
      >
        Alert !
      </div>
      <div @click="$bvModal.hide('someprob')" class="modalcloseicon pointer">
        <img
          class="rotate-on-hover"
          src="../assets/newimgs/closeicon.svg"
          alt=""
        />
      </div>
      <div
        class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
      >
        <div>
          <img src="../assets/newui-img/alertimage.svg" alt="" />
        </div>
        <div class="newui-modal-bodytxt mt-4">
          "Due to some inconvenience, you cannot start the test."
        </div>
        <div class="bluebtns mt-5">
          <b-button
            class="mr-1 px-4"
            variant="primary"
            @click="$bvModal.hide('someprob')"
            >Okay</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import dosDonts from "../mixins/dosDonts";
import Spinner from "../components/fixedcomponents/Spinner.vue";
export default {
  mixins: [dosDonts],
  components: {
    Spinner,
  },
};
</script>
<style>
@media only screen and (max-width: 821px) {
  .screenSizeLap {
    display: none;
  }
}
@media only screen and (min-width: 821px) {
  .screenSizeMobile {
    display: none;
  }
}
.dd-tooltip {
  background: #d9d9d9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  width: 185px;
  height: 48px;
  position: absolute;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 10px;
  display: none;
  text-align: center;
  transform: translateX(-55%);
  color: #696969;
  padding: 5px 10px;
  top: -54px;
  left: 30%;
  white-space: no-wrap;
}
.dd-tooltip-trigger:hover .dd-tooltip {
  display: block;
}
.dd-tooltip::before {
  content: "";
  position: absolute;
  top: 48px;
  left: 74%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  transform: rotate(180deg);
  border-color: transparent transparent #d9d9d9 transparent;
}
.dnd-hr {
  border-bottom: 1px solid #fff;
  margin-top: 0px;
  margin-bottom: 10px !important;
  width: 100%;
}
.dodontlis ol li {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #3a3c40;
}
.dosndontstep-subtext {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  opacity: 0.4;
}
/* .dosndosnts-proc-tooltip {
  background: #d9d9d9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  width: 185px;
  height: 48px;
  position: absolute;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 10px;
  display: none;
  text-align: center;
  transform: translateX(-55%);
  color: #696969;
  padding: 5px 10px;
  top: -54px;
  left: 30%;
}
.dosdontbtnhover:hover .dosndosnts-proc-tooltip {
  display: block;
}

.dosndosnts-proc-tooltip::before {
  content: "";
  position: absolute;
  top: 48px;
  left: 74%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  transform: rotate(180deg);
  border-color: transparent transparent #d9d9d9 transparent;
} */
</style>
